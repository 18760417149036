.canvas
  height: 100%
  width: 100%
  position: relative
  overflow: auto

  &.documentViewMode
    display: none

#selection
  position: absolute
  z-index: 1000
  border-radius: 2px
  pointer-events: none
  border: 1px solid rgba(74, 169, 233, 0.741)
  background: rgba(74, 169, 233, 0.05)


.dragPreview
  position: relative

  .test
    font-size: 5rem
