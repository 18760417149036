.loader
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 0.5rem
  background: rgba(20, 20, 20, 0.85) // Dark translucent background
  padding: 1rem
  border-radius: 8px
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)
  z-index: 1000
  color: #FFFFFF // White text
  font-family: 'Roboto', sans-serif
  font-size: 0.9rem
  text-align: center

.spinner
  width: 30px
  height: 30px
  border: 3px solid rgba(255, 255, 255, 0.3)
  border-top: 3px solid #4A90E2 // Blue spinner color
  border-radius: 50%
  animation: spin 1s linear infinite

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

.progressContainer
  position: relative
  width: 100%
  max-width: 200px
  height: 6px
  background-color: rgba(255, 255, 255, 0.2)
  border-radius: 3px
  overflow: hidden

.progressBar
  position: absolute
  height: 100%
  background: linear-gradient(90deg, #4A90E2, #8B6CFC) // Blue and soft purple gradient
  transition: width 0.3s ease-in-out

.progressText
  font-size: 0.8rem
  margin-top: 0.3rem
