.aiContent 
    display: flex
    justify-content: center
    align-items: center
    flex-grow: 1
    border-radius: .25rem
    min-height: 48px
    padding-right: .5rem
    background: linear-gradient(20deg, #348F5044, #56B4D344)

.aiContentIconWrapper 
    border-radius: 16px
    display: flex
    justify-content: center
    align-items: center
    margin-left: 12px
    margin-right: 12px
    height: 18px
    width: 18px
    user-select: none
    cursor: pointer

.inlineContent 
    flex-grow: 1

.askButton
    background-color: none
    border: none

.runButton
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    gap: .125rem
    font-size: .75rem
    border: 1px solid rgba(0, 0, 0, 0.1)
    border-radius: .25rem
    padding: .125rem .25rem
    transition: all 0.1s
    opacity: 0.8
    &:hover
        transform: scale(1.08)
        opacity: 1
        border-color: rgba(0, 0, 0, 0.3)
        background: rgba(0, 0, 0, 0.05)