//-----------AI bubbles-----------
[data-node-type=blockOuter][data-background-color=ai-single]
	padding: 1rem 0

[data-node-type=blockOuter][data-background-color=ai-start]
	padding: 1rem 0 0

[data-node-type=blockOuter][data-background-color=ai-middle]
	padding: 0

[data-node-type=blockOuter][data-background-color=ai-end]
	padding: 0 0 1rem

//-----------Human bubbles-----------
[data-node-type=blockOuter][data-background-color=hu-single]
	padding: .5rem 1rem
	border-radius: var(--border-radius-medium)
	width: 70%
	margin-left: 30%
	background: rgba(0, 0, 0, .05)
	border: 1px solid var(--border-color)

[data-node-type=blockOuter][data-background-color=hu-start]
	padding: .5rem 1rem 0
	border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0
	width: 70%
	margin-left: 30%

[data-node-type=blockOuter][data-background-color=hu-middle]
	padding: 0 1rem 0
	border-radius: 0
	width: 70%
	margin-left: 30%

[data-node-type=blockOuter][data-background-color=hu-end]
	padding: 0 1rem .5rem
	border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium)
	width: 70%
	margin-left: 30%

.sideHandle
	opacity: 0.3

.blocknote>div
	overflow-anchor: none

.document
	min-width: 320px
	width: 100%
	height: 100%
	background: var(--background-color)
	transition: .3s all
	flex: 0 0 1
	position: relative
	height: calc(100%)
	overflow: auto
	cursor: text
	transform: translateX(0)

	img
		width: 100%

	&.canvasViewMode
		transform: translateX(-100%)
		width: 0
		min-width: 0
		transition: .3s all
		overflow: hidden
		padding: .5rem 0
		margin: .5rem

	&.hybridViewMode
		background: var(--canvas-bubble-bg-color)
		transition: background .3s ease-out
		margin: .5rem
		border-radius: var(--border-radius-medium)
		transform: translateX(0)
		height: calc(100% - 1rem)
		border: 1px solid var(--border-color)
		.textContent
			padding-top: 1rem

	&.documentViewMode
		width: 50%
		margin: .5rem 25%
		border-radius: var(--border-radius-medium)
		border: 1px solid var(--border-color)
		.textContent
			padding-top: 1rem

		.resizeHandle
			display: none

.resizeHandle
	position: absolute
	top: 0
	right: 0
	width: 2px
	height: 100%
	background: var(--canvas-bubble-border-color)
	cursor: col-resize
	transition: .1s all

	&:hover
		background: deepskyblue

.textContent
	padding: 0.5rem 1rem 0.5rem 0.5rem
	height: 100%

em
	font-style: italic

strong
	font-weight: bold

/* in document view mode, make full width on smaller screens */
@media (max-width: 800px)
	.document.documentViewMode
		width: 100%
		margin: 0
		box-shadow: none
