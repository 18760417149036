.invite
  padding: 8rem 2rem 2rem
  text-align: center
  background: var(--background-color)
  height: 100vh
  text-wrap: pretty
  color: var(--text-color)


  .inviteBox
    margin-top: 5rem

    h2
      margin-bottom: 1rem
      font-weight: 500
      font-size: 1.25rem

    input
      padding: 0 1rem
      line-height: 1.875rem
      width: 320px
      border: 1px solid var(--border-color)
      border-radius: var(--border-radius-small)
      background: var(--background-color)
      color: var(--text-color)
    
    button
      padding: 0 .75rem
      cursor: pointer
      border: 1px solid var(--border-color)
      border-radius: var(--border-radius-small)
      background: var(--background-color)
      transition: .1s all
      margin: 0 .25rem
      color: var(--text-color)
      font-size: .8125rem
      line-height: 1.875rem
      &:hover
        background: var(--button-hover-color)
      svg
        vertical-align: middle
        transform: translateY(-1.5px)
        margin-right: .25rem
        width: 1rem
