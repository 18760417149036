@property --color1
  syntax: '<color>'
  inherits: false
  initial-value: #72bbff

  /* Initial gradient color

@property --color2
  syntax: '<color>'
  inherits: false
  initial-value: #ffffff

  /* Initial gradient color

@property --gradient-angle
  syntax: '<angle>'
  inherits: false
  initial-value: 45deg

  /* Initial gradient angle

.background
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: -1
  overflow: hidden

.design
  position: relative
  width: 100%
  height: 100%

.circle
  position: absolute
  border-radius: 50%
  opacity: 0.5

.float
  @keyframes float
    0%
      transform: translate(0, 0)
    25%
      transform: translate(200px, 200px)
    50%
      transform: translate(0, 400px)
    75%
      transform: translate(-200px, 200px)
    100%
      transform: translate(0, 0)
