dialog
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius-medium)
    box-shadow: var(--box-shadow)
    padding: 2rem
    max-width: 640px

::backdrop
    transition: .3s all
    background: linear-gradient(30deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .3))

.latestPrompts
    margin-top: 1rem
    margin-bottom: 1rem

    .latestPromptsTitle
        font-weight: 700
        font-size: 1rem
        margin-bottom: 0.75rem

    .latestPromptsList
        margin-bottom: 0.5rem
        cursor: pointer
        margin-left: 2rem


.buttons
    display: flex
    gap: .5rem


html[data-theme="dark"]
    dialog
        background: linear-gradient(30deg, #18191A, #13161C)
        color: var(--text-color)
