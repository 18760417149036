.subscription
  color: var(--text-color)
  text-align: center
  padding-top: 10rem
  position: relative

  h1
    margin: 0

.benefits
  display: flex
  justify-content: center
  gap: 3rem
  margin: 4rem auto 3.5rem
  text-align: left
  font-size: 1rem
  font-weight: 500
  .group
  p
    margin-bottom: 1rem
    &::before
      content: '●'
      margin-right: .5rem

.cta
  display: flex
  align-items: center
  gap: .5rem
  justify-content: center
  transform: scale(1.1)
  margin-bottom: 1rem

.indicator
  width: .5rem
  height: .5rem
  border-radius: 1rem
  display: inline-block
  background: lime


.subscribeButton
  width: 240px
