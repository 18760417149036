.checklistItem
  display: flex
  align-items: flex-start
  gap: 6px
  width: 100%

  .checkbox
    width: 16px
    height: 16px
    flex-shrink: 0
    margin-top: 2px 

  .checklistContent
    flex: 1
    word-break: break-word
    min-width: 0
    display: flex
    align-items: center
    text-align: start
    position: relative 

    &.checked
      text-decoration: line-through
      color: gray

    .placeholder
      color: gray
      opacity: 0.6
      user-select: none
      position: absolute
      left: 0
