
.promptModule
	position: fixed
	bottom: 0
	left: 50%
	transform: translateX(-50%) scale(0.9)
	transform-origin: bottom center
	width: 30%
	min-width: 600px
	background: var(--background-color)
	color: var(--text-color)
	z-index: 1
	border-radius: var(--border-radius-small)
	border-bottom-left-radius: 0
	border-bottom-right-radius: 0
	padding: .5rem
	box-shadow: var(--box-shadow)
	border: 1px solid var(--canvas-bubble-border-color)
	border-bottom: none
	transition: all .1s
	&:focus-within, &.showFull
		width: 50%
		.selectScope
			display: block
	&:hover, &:focus-within, &.showFull
		transform: translateX(-50%) scale(1)
	.aiIcon img
		width: 2rem
		height: 2rem

	.selectScope
		margin: .125rem 0 .5rem
		display: none
		font-size: .75rem
		.scopes
			display: flex
			align-items: center
			> div
				cursor: pointer
				padding: .125rem .5rem
				user-select: none
				border: 1px solid var(--border-color)
				border-radius: var(--border-radius-small)
				&.selected
					background: var(--text-color)
					color: var(--background-color)
				&:first-child
					border-top-right-radius: 0
					border-bottom-right-radius: 0
				&:nth-child(2)
					border-top-left-radius: 0
					border-bottom-left-radius: 0
					margin-right: .5rem

	.useSelected
		border-radius: 0.5rem
		display: flex
		align-items: center
		border: 1px solid var(--border-color)
		padding: .125rem .5rem .125rem .25rem
		user-select: none
		&:has(input:disabled)
				opacity: .3
		&:has(input:checked) 
				border-color: var(--text-color)
				opacity: 1
		&:hover
				border-color: var(--hover-border-color)
		label, input
			cursor: pointer
			color: var(--text-color)
			width: auto
	

	.inputContainer
		display: flex
		width: 100%
		gap: .5rem
		align-items: center
	.input
		flex: 1 1 auto
		padding: .5rem
		border-radius: var(--border-radius-small)
		border: 1px solid var(--border-color)
		background: rgba(0, 0, 0, 0.05)
		&:empty:before
			content: attr(data-placeholder)
			pointer-events: none
			display: block
			opacity: .5
	
	.send
		cursor: pointer
		background: var(--text-color)
		width: 2rem
		height: 2rem
		color: var(--background-color)
		text-align: center
		border-radius: .5rem
		&:hover
			opacity: .7

	.document
		overflow: auto
		position: relative
		max-height: 40vh
		flex: 1
		flex-basis: 0
		min-height: 63px
		display: none
		margin-top: -.5rem
		overflow-anchor: auto
		&.documentCollapse
			display: none
	
	.header
		border-bottom: 1px solid var(--border-color)
		margin: -.5rem
		margin-bottom: .5rem
		padding: .5rem
		display: none
		justify-content: space-between
		align-items: center
		position: relative
		cursor: grab
		.headerButtons
			display: flex
			gap: .25rem

.activeChat
	.header
		display: flex
		
.titleSpinner
	display: flex
	gap: 5px
		
.spinner
	animation: spin 1s infinite

.showFull
	width: 50%
	.selectScope, .document
		display: block
	.header .headerButtons
		display: flex

@media (max-width: 768px)
	.promptModule

:global
	.promptModule
		.button
			padding: .6rem .75rem


@keyframes spin 
		100% 
			transform: rotate(360deg)
	