*
  box-sizing: border-box
  outline: none

:root
  --text-color: #000
  --background-color: #fff
  --font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif
  --font-weight: 400
  --border-color: rgba(0, 0, 0, .25)
  --border-radius-small: .5rem
  --border-radius-medium: 1rem
  --button-hover-color: #f9f9f9
  --hover-border-color: rgba(0, 0, 0, .5)

  --box-shadow: 0 0 1rem rgba(0, 0, 0, .1)
  --box-shadow-small: .1rem .1rem 2px rgba(0, 0, 0, .1)
  --box-shadow-small-hover: .125rem .125rem .5rem rgba(0, 0, 0, .1)
  --canvas-bg-color-1: rgb(235, 219, 234)
  --canvas-bg-color-2: rgb(186, 244, 248)

  --canvas-bubble-bg-color: rgba(255, 255, 255, 1)
  --canvas-bubble-border-color: rgba(0, 0, 0, .3)

  --link-color: #000
  --visited-link-color: #666

  --error-color: #f8d7da
  --error-text-color: #541c20


html[data-theme="dark"]
  --text-color: #e4e6eb
  --background-color: #18191A
  --button-hover-color: rgba(255, 255, 255, .05)
  --hover-border-color: rgba(255, 255, 255, .4)
  --border-color: rgba(255, 255, 255, .1)
  --canvas-bg-color-1: #20283a
  --canvas-bg-color-2: #13161c

  --box-shadow: 0 0 1rem rgba(255, 255, 255, .1)

  --canvas-bubble-bg-color: linear-gradient(30deg, #18191A, #13161C)
  --canvas-bubble-border-color: rgba(255, 255, 255, .3)

  --button-bg-color: hsla(0, 0%, 10%, .95)
  --button-border-color: rgba(255, 255, 255, .3)
  --button-hover-border-color: rgba(255, 255, 255, .8)

  --link-color: #999
  --visited-link-color: #999

:global
  a
    color: var(--link-color)
    &:visited
      color: var(--visited-link-color)
  .sideHandle
    width: 1.5rem
    height: 1.5rem
    font-size: 1.25rem
    font-weight: bold
    border-radius: 5px
    cursor: grab
    opacity: .3
    text-align: center
    line-height: 1.5rem
    &:hover
      background: var(--border-color)

html
  margin: 0
  padding: 0
  width: 100%
  height: 100vh
  overflow: hidden


body
  margin: 0
  padding: 0
  width: 100%
  height: 100vh
  font-family: var(--font-family)
  font-weight: var(--font-weight)
  background: var(--background-color)
  line-height: 1.4
  font-size: 14px
  overflow: hidden

input, button
  font-family: var(--font-family)
  font-weight: var(--font-weight)
.loading, .loading *
  cursor: wait !important

/* Dirty tweaks for BlockNote */
:global
  .bn-block-content
    font-size: .875rem
    font-family: var(--font-family)
    font-weight: var(--font-weight)

  .bn-container
    --bn-colors-editor-background: transparent !important
    --bn-colors-editor-text: var(--text-color) !important

  .bn-editor
    padding: 0 .25rem 0 1rem !important

  .name-editor .bn-editor
    padding: 0 !important

    .bn-block-content
      font-size: 1.125rem !important

    .bn-block-content[data-is-empty-and-focused] .bn-inline-content:has(> .ProseMirror-trailingBreak:only-child)::before
      content: "Enter a name..."
      
  #canvas .name-editor .bn-editor .bn-block-content
    font-size: .875rem !important


  [data-level="1"]
    --level:2em !important

  [data-level="2"]
    --level:1.5em !important

  [data-level="3"]
    --level:1.25em !important

  .bn-block-outer:not([data-prev-type])>.bn-block>.bn-block-content[data-content-type=bulletListItem]:before
    font-size: 1.25rem
    transform: translateY(2px)
    line-height: 1rem

  html[data-theme="dark"]
    .bn-block-content[data-is-empty-and-focused] .bn-inline-content:has(> .ProseMirror-trailingBreak:only-child)::before
      opacity: .3
